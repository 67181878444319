import React, { useContext } from 'react';
import { Link } from 'gatsby';

import { Layout, Seo } from 'components';
import { appContext } from 'providers/appProvider';
import { CommonContainer } from 'components/common-container';
import ContentBlock from 'components/content-block';

import './sitemap.scss';
// import { Grid } from "reflexbox"

const Sitemap = props => {
  const { appConfigs } = useContext(appContext);
  const siteIndications = appConfigs.siteOptions.INDICATIONS;
  return (
    <Layout indication={siteIndications.hcp} className="hcp-sitemap">
      <Seo indication={siteIndications.hcp} />
      <CommonContainer>
        <ContentBlock>
          <h2>SITEMAP</h2>
          <div className="link-container">
            <ul>
              <li>
                <Link className="link" to={'/'}>
                  {'Home'}
                </Link>
              </li>
              <ul>
                <li>
                  <Link className="link" to={'/#about'}>
                    {'About SPRIX®'}
                  </Link>
                </li>
                <li>
                  <Link className="link" to={'/#profiles'}>
                    {'Patient Profiles'}
                  </Link>
                </li>
              </ul>
              <li>
                <Link className="link" to={'/clinical-data'}>
                  {'Clinical Data'}
                </Link>
              </li>
              <ul>
                <li>
                  <Link className="link" to={'/clinical-data#rapid'}>
                    {'Rapid Absorption'}
                  </Link>
                </li>
                <li>
                  <Link className="link" to={'/clinical-data#proven'}>
                    {'Proven Efficacy'}
                  </Link>
                </li>
                <li>
                  <Link className="link" to={'/clinical-data#safety'}>
                    {'Safety'}
                  </Link>
                </li>
              </ul>
              <li>
                <Link className="link" to={'/prescribing-sprix'}>
                  {'Prescribing SPRIX®'}
                </Link>
              </li>
              <ul>
                <li>
                  <Link className="link" to={'/prescribing-sprix#dosing'}>
                    {'Dosing'}
                  </Link>
                </li>
                <li>
                  <Link className="link" to={'/prescribing-sprix#storage'}>
                    {'Storage'}
                  </Link>
                </li>
                <li>
                  <Link className="link" to={'/prescribing-sprix#direct'}>
                    {'SPRIX® Direct'}
                  </Link>
                </li>
                <li>
                  <Link className="link" to={'/prescribing-sprix#prescribe'}>
                    {'Ways to Prescribe'}
                  </Link>
                </li>
              </ul>
              <li>
                <Link className="link" to={'/resources'}>
                  {'Tools'}
                </Link>
              </li>
              <li>
                <Link className="link" to={'/videos'}>
                  {'Videos'}
                </Link>
              </li>

              <li>
                <Link className="link" to={'/get-in-touch'}>
                  {'Get In Touch'}
                </Link>
              </li>
              <ul>
                <li>
                  <Link className="link" to={'/get-in-touch#request'}>
                    {'Request A Rep'}
                  </Link>
                </li>
                <li>
                  <Link className="link" to={'/get-in-touch#cardinal'}>
                    {'Cardinal Health Specialty Pharmacy'}
                  </Link>
                </li>
                <li>
                  <Link className="link" to={'/get-in-touch#contact'}>
                    {'Contact Us'}
                  </Link>
                </li>
              </ul>
            </ul>
          </div>
        </ContentBlock>
      </CommonContainer>
    </Layout>
  );
};

export default Sitemap;
